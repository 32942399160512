import { resize } from "../../shared/utils";
function getCss({ style: { size_scale } }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: "",
      declarations: {
        display: "flex",
        position: "relative",
      },
    },
    {
      selector: " .language-list",
      declarations: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        width: "maxContent",
      },
    },
    {
      selector: ".wg-left .language-list",
      declarations: {
        right: "0",
        left: "auto",
        flexDirection: "row-reverse",
        marginRight: `${scale(50)}px`,
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
      },
    },
    {
      selector: ".wg-right .language-list",
      declarations: {
        right: "auto",
        left: "0",
        flexDirection: "row",
        marginLeft: `${scale(50)}px`,
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
      },
    },
    {
      selector: " .language-option",
      declarations: {
        display: "flex",
        height: `${scale(50)}px`,
        width: `${scale(50)}px`,
        padding: `${scale(15)}px`,
        fontSize: `${scale(14)}px`,
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        transition: "all .2s",
      },
    },
    {
      selector: ".closed .language-list .language-option",
      declarations: {
        width: "0px",
        pointerEvents: "none",
        opacity: "0",
      },
    },
    {
      selector: ".closed .active-language",
      declarations: {
        borderRadius: 0,
      },
    },
    {
      selector: " .language-option a",
      declarations: {
        textDecoration: "none",
        outline: "none",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      },
    },
  ];
}

export default getCss;
