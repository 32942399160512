import { h } from "preact";
import { useState } from "preact/hooks";

import { getLanguageName, getColor, keycodes } from "../../shared/utils";

const Option = ({
  styleOpts,
  colors,
  language,
  onClick = null,
  url,
  onMouseEnter = null,
  isFocused = false,
  active = false,
  focusRef = null,
  tabIndex,
}) => {
  const { full_name = true } = styleOpts;
  const languageName = getLanguageName(language);
  const name = full_name ? languageName : language.toUpperCase();
  const [hover, setHover] = useState(false);

  function handleClick(e, l) {
    e.preventDefault();
    if (onClick) {
      onClick(l);
    }
  }

  function handleMouseEnter() {
    if (onMouseEnter) {
      onMouseEnter();
    }
    setHover(true);
  }

  const { backgroundColor, textColor } = getColor(colors, {
    active,
    hover: hover || isFocused,
  });

  return (
    <div
      className={`language-option${active ? " active-language" : ""}`}
      style={{
        backgroundColor,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setHover(false)}
      {...(active
        ? {
            "aria-label": `Language selected: ${languageName}`,
          }
        : {
            role: "option",
          })}
      tabIndex={tabIndex}
      onClick={e => handleClick(e, language)}
      onKeyDown={e => {
        if (e.keyCode === keycodes.enter) {
          handleClick(e, language);
        }
      }}>
      <a
        href={url}
        aria-label={name}
        tabIndex={-1}
        {...(isFocused && !active && { ref: focusRef })}
        style={{
          ...(!full_name && { fontWeight: 700 }),
          color: textColor,
        }}
        id={`weglot-language-${language}`}>
        {name.toUpperCase()}
      </a>
    </div>
  );
};

export default Option;
